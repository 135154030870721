<template>
  <div id="divCookiePolicyWrapper" class="cookie-policy-wrapper">
    <BrandButton class="cookie-policy-wrapper-button" @click="showCookieModal = true"
      >Çerez Politikalarını Düzenlemek için Tıklayın</BrandButton
    >
    <h2>Çerez Bildirimi</h2>
    <p>
      Bu bildirimde dijital platformlarımızda (çerezler ve web işaretleyicileri gibi çerez bazlı
      olmayan diğer teknolojiler gibi) izleme teknolojilerinden nasıl yararlandığımız, hangi
      ziyaretçi bilgilerinin otomatik olarak toplandığı ve de kullandığımız izleme teknolojilerini
      ne şekilde kabul edebileceğiniz veya reddedebileceğiniz açıklanmaktadır.
    </p>
    <h2>İzleme teknolojilerini nasıl kullanıyorsunuz?</h2>
    <p>Dijital platformlarımızda</p>
    <ul>
      <li>platformların çalışmasını sağlama veya daha etkin şekilde çalışmasını sağlama</li>
      <li>dijital platformlarımıza gelişmiş bir işlevsellik kazandırma</li>
      <li>
        ziyaretçilerin dijital platformlarımızı ne şekilde kullandığına ve mesajlarımızla ne şekilde
        etkileşim kurduğuna dair bilgi temin etme ve
      </li>
    </ul>
    <h2>Çerezler veya web işaretçileri nedir?</h2>
    <p>
      Çerezler, izleme teknolojilerinin bir örneğidir. Bunlar dijital bir platformu ziyaret
      ettiğinizde bilgisayarınıza yerleşen ve daha sonra aşağıda belirtilen amaçlarla
      bilgisayarınızın kimliğini tespit etme amacıyla kullanılan küçük metin dosyalarıdır.
    </p>
    <p>
      Çerezler "birinci taraf çerezleri" adı verilen bir dijital platformun sahibi tarafından
      yerleştirilir. Diğer kişilerce yerleştirilen çerezler "üçüncü taraf çerezleridir". Üçüncü
      taraf çerezleri, üçüncü tarafın dijital platform üzerinde veya onun aracılığıyla (analiz,
      reklam ve video gibi) özellikler ve işlevler sunmasına imkan verir. Üçüncü taraf çerezleri
      yerleştiren taraflar cihazınız dijital platformu ziyaret ettiğinde ve diğer dijital
      platformları ziyaret ettiğinde cihazını tanıyabilir.
    </p>
    <p>
      Web işaretleyicileri, dijital platformlarımıza ve (e-posta dahil) bültenlerimize dahil
      edilebilen ve genellikle cihazınızın veya tarayıcınızın kimliğinin ve internet üzerindeki
      davranışlarınızın belirlenmesi için birlikte kullanılan küçük grafik verileridir (ayrıca
      "Piksel Etiketleri" veya "Açık GIF'ler olarak bilinmektedir).
    </p>
    <p>
      Web işaretleyicileri, özellikle ilgili çerezin kullanımına reddetmiş olmanız halinde
      kullanılmayacaktır. Web sitelerimizin ve e-posta güncellemeleri gibi Hizmetlerimizin bazıları,
      "web işaretleyicileri" adı verilen (ayrıca "tek pikselli GIF'ler" veya "açık GIF'ler" veya
      "piksel etiketleri" olarak da bilinen) elektronik resimler içerebilmektedir. Web
      işaretleyicileri web sitelerinde veya sayfalarda içerik görüntülemiş olan ziyaretçileri
      saymamıza imkan verir. E-posta mesajlarında ve bültenlerde web işaretleyicileri kaç abonenin
      bunları okuduğunu saymamıza yardımcı olur. Bunlar ayrıca size daha kişiselleştirilmiş bir
      içerik sunmak için ilginizi çeken faaliyetler ve özellikler hakkında istatistiki bilgi
      geliştirmemize yardımcı olur. Onayınız olmaksızın kişisel verilerinize erişmek için web
      işaretleyicileri kullanmamaktayız.
    </p>
    <h2>
      İzleme teknolojileriniz ne gibi bilgiler toplar? Bunları kullanıp kullanmayacağınızı ben
      kontrol edebilir miyim?
    </h2>
    <p>
      İzleme teknolojilerini dijital platformlarımıza erişen cihazlar (ve dolayısıyla bu
      platformları kullanan ziyaretçiler) hakkında belirli bilgileri toplamak için kullanıyoruz.
      Aşağıdaki tabloda bilgi türleri ve bilginin kullanıp kullanılmamasını ne şekilde kontrol
      edebileceğiniz gösterilmiştir:
    </p>
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Teknoloji kategorisi</th>
            <th>Teknoloji amacı</th>
            <th>Kullanım örneği</th>
            <th>Toplanan bilgi türü</th>
            <th>Kullanımı nasıl kontrol edebileceğiniz</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Kesinlikle gerekli</td>
            <td>Dijital platformun ve özelliklerinin sunulması.</td>
            <td>Cihazınızda uygun şekilde içerik görüntülenmesi.</td>
            <td>Cihazın IP adresi, tarayıcı türü, ekran çözünürlüğü.</td>
            <td>
              Dijital platformun ve hizmetlerin çalışması için zorunlu olduğundan bunu tercih etmeme
              şansınız yoktur.
            </td>
          </tr>
          <tr>
            <td>İşlevsellik</td>
            <td>Dijital platformun ve özelliklerinin uygun bir şekilde sunulması.</td>
            <td>
              Kim olduğunuzu, tercihlerinizi ve ayarlarınızı hatırlamak, böylelikle sayfayı
              değiştirdiğiniz her an bunları girmenizin gerekmemesi. Örneğin:
              <br />
              • güvenli bir alana bağlanmanız
              <br />
              • tercih edilen bir dil seçmeniz
              <br />
              • bölgesel ayarları seçmeniz halinde.
            </td>
            <td>
              Cihazın IP adresi ile birlikte dil seçimleri veya bölge gibi girmiş olduğunuz tercih
              bilgileri.
            </td>
            <td>
              Tarayıcı ayarlarınızı kullanarak işlevsellik çerezlerini nasıl tercih etmeyeceğinizi
              öğrenmek için <a href="https://www.aboutcookies.org/">buraya tıklayın.</a> Bunu tercih
              etmemenin dijital platformu kullanırken size sunulan işlevselliği etkileyebileceğini
              unutmayın. Flash çerezlerinin gizlilik ve saklama ayarlarını ne şekilde
              yönetebileceğinizi öğrenmek için
              <a
                href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html#117118"
                >buraya tıklayın.</a
              >
            </td>
          </tr>
          <tr>
            <td>Performans ölçümü</td>
            <td>Dijital platformun çalışma şeklini geliştirmemize yardımcı olmak.</td>
            <td>
              İnsanların platformumuzu nasıl kullandığını veya platformumuzun ne kadar iyi
              performans gösterdiğini öğrenmek. Ancak bu teknolojilerden sadece anonim bilgiler
              almaktayız. Bu çerezlerin topladığı bilgiler sadece bulunduğunuz bölgeyi tanımlamak
              için azaltılır ve daha sonra bu bilgiler bize teslim edilmeden önce toplu hale
              getirilir.
            </td>
            <td>
              Kaç kişinin dijital platformumuzu ziyaret ettiği, dijital platformumuza nereden
              geldikleri ve (en popüler sayfalar, kaç kişinin sitede gezindiği, sitede ne kadar
              vakit geçirdiği, hata mesajları vs. gibi) dijital platformumuzu ne şekilde
              kullandıkları.
            </td>
            <td>
              Tarayıcı ayarlarınızı kullanarak performans çerezlerinin kullanımını tercih
              etmeyebilirsiniz. Bunu yapma şeklinizin tarayıcıdan tarayıcıya farklılık gösterdiğini
              unutmayın. Bu yüzden lütfen tarayıcınızın yardım menüsüne bakın. Alternatif olarak
              <a href="http://www.aboutcookies.org/">buraya tıklayabilirsiniz.</a> Google tarafından
              yerleştirilen çerezlerin kullanımını kontrol etmek için aşağıya bakınız.
            </td>
          </tr>
          <tr>
            <td>Hedefleme veya reklam</td>
            <td>
              Kullanıcıya hedeflemeli reklam sunmak. Satış ekibinin kullanımı ve de dijital
              platformu ziyaret eden kişilerden gelen hacimle ilgili istatistiki bilgileri izlemek
              ve analiz etmek için üçüncü taraflardan yararlanır.
            </td>
            <td></td>
            <td>
              IP adresleri ve Web işaretleyicilerinden gelen diğer bilgiler (bu işaretleyiciler
              sadece dijital platform üzerinde, e-postalarda veya üçüncü taraf dijital platformlarda
              olabilir).
            </td>
            <td>
              Bu ve diğer reklam ağları ve bunların kullanımının tercih edilmemesine ilişkin
              talimatlar hakkında daha fazla bilgi almak için
              <a href="https://www.networkadvertising.org/choices/">buraya</a> ve
              <a href="https://www.aboutads.info/choices/">buraya tıklayın.</a> Flash çerezlerinin
              gizlilik ve saklama ayarlarını ne şekilde yönetebileceğinizi öğrenmek için buraya
              tıklayın . Yine de ilgi alanına dayalı olmayan reklamlar alabilirsiniz.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>Ayrıca tarayıcınızın ayarları aracılığıyla da tarayıcı çerezlerini kontrol edebilirsiniz.</p>
    <h2>Google analytics veya başka web sitesi analiz araçları kullanıyor musunuz?</h2>
    <p>
      Bu etkileşim noktası Google, Inc. ("Google") tarafından sunulan bir analiz hizmeti olan Google
      Analytics'i kullanmaktadır. Google Analytics, etkileşim noktasının kullanıcıların etkileşim
      noktasını ne şekilde kullandığını analiz etmesine yardımcı olmak için çerezlerden
      yararlanmaktadır. Çerez tarafından etkileşim noktasını kullanımınız hakkında üretilen bilgiler
      (IP adresiniz dahil) ABD'de bulunan Google'a iletilecektir. IP adresinin kaynağı neresi olursa
      olsun (Avrupa Birliği Üye Ülkeleri, Avrupa Ekonomik Alanı Anlaşmasının diğer tarafları veya
      başka bir yer), Google (IPv4 adreslerinde) IP adresinin son oktetini veya (IPv6 adreslerinde)
      son 80 biti anonim hale getirmektedir ("IP maskeleme" adı verilen bir işlem). Google bunu tüm
      ülkelerde "anonymizeIP" (IP anonimleştirme) sistemini devreye koyduğumuz için yapmaktadır. Son
      olarak Google anonim hale getirilmiş olan verileri ABD'de bulunan sunucular üzerinde
      saklamaktadır. Google bu bilgileri bizim adımıza etkileşim noktasını kullanımınızı
      değerlendirme, bizler için web sitesi faaliyetine ilişkin rapor derleme ve bize etkileşim
      noktası faaliyetleriyle ve internet kullanımıyla ilgili diğer hizmetleri sunma amacıyla
      kullanmaktadır. Google IP adresinizi elinde bulunan başka herhangi bir veriyle
      ilişkilendirmeyecektir.
    </p>
    <p>
      Yukarıdaki tabloda açıklandığı üzere, tarayıcı eklentisini
      <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB">buradan</a> indirerek ve
      yükleyerek Google'ın veri (çerez ve IP adresi) toplamasını ve kullanmasını
      engelleyebilirsiniz.
    </p>
    <p>
      <a href="http://www.google.com/analytics/terms/gb.html"
        >Google analytics'i kullanımımıza ilişkin hüküm ve koşullar</a
      >, harici referanslar, Google'ın veri gizliliği ve güvenliği konusunda sunduğu bilgiler ve
      <a href="https://www.google.com/intl/en_uk/policies/">Google’ın veri gizliliği bildirimi</a>
      konusunda daha fazla bilgi için bu bağlantılara tıklayın.
    </p>

    <BrandCookiesModal :show.sync="showCookieModal"></BrandCookiesModal>
  </div>
</template>
<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import BrandCookiesModal from '@/components/brand/Modals/BrandModal/BrandCookiesModal';
export default {
  name: 'CookiePolicy',
  components: {
    BrandButton,
    BrandCookiesModal,
  },
  data() {
    return {
      showCookieModal: false,
    };
  },
  mounted() {
    document
      .getElementById('divCookiePolicyWrapper')
      .querySelectorAll('a')
      .forEach(x => {
        x.setAttribute('target', '_blank');
      });
  },
};
</script>
<style lang="scss" scoped>
.cookie-policy-wrapper {
  width: 100%;
  padding-left: palette-space-level('10');
  padding-right: palette-space-level('10');
  margin-bottom: palette-space-level('30');
  margin-top: palette-space-level('20');
  .table-wrapper {
    overflow: auto;
    font-size: small;
    max-width: 100%;
  }
  &-button {
    border-radius: 4px;
    margin-bottom: palette-space-level('20');
  }

  a {
    color: palette-color-level('grey', 40) !important;
  }

  h2 {
    padding: 5px 0;
    font-size: 18px !important;
    font-weight: 500;
  }
  p,
  h2 {
    margin-bottom: 10px;
  }
  table {
    th,
    td {
      border: 1px solid palette-color-level('grey', 20);
      padding: 5px;
    }
  }
}
</style>
